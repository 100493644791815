<!--
 * @Author: 肖槿
 * @Date: 2021-10-25 09:57:19
 * @Description: 查看商品
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-10-26 19:06:52
 * @FilePath: \s2b-admin\src\pages\admin\goods\featured\detail.vue
-->
<template>
  <div class="featured-detail">
    <a-form-model
      :model="spiderForm"
      ref="spiderRef"
      class="featured-condition-form"
      layout="inline"
    >
      <a-form-model-item label="商品ID" prop="goods_id">
        <a-input placeholder="请输入商品ID" v-model="spiderForm.goods_id" />
      </a-form-model-item>
      <a-form-model-item label="关键词" prop="goods_title">
        <a-input placeholder="请输入商品关键词" v-model="spiderForm.goods_title" />
      </a-form-model-item>
      <a-form-model-item label="商品类目" prop="goods_first_cid">
        <a-select
          style="width: 183px;"
          v-model="spiderForm.goods_first_cid"
          placeholder="请选择商品类目"
          show-search
          :filter-option="filterCategoryOption"
        >
          <a-select-option
            v-for="item in categoryList"
            :key="item.id + '_category'"
            :value="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="价格区间">
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px', marginRight: '0' }"
          prop="goods_price_min"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最低价格"
            v-model="spiderForm.goods_price_min"
          />
        </a-form-model-item>
        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px' }"
          prop="goods_price_max"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最高价格"
            v-model="spiderForm.goods_price_max"
          />
        </a-form-model-item>
      </a-form-model-item>
      <a-form-model-item label="历史销量">
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px', marginRight: '0' }"
          prop="goods_sell_num_min"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最低历史销量"
            v-model="spiderForm.goods_sell_num_min"
          />
        </a-form-model-item>
        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px' }"
          prop="goods_sell_num_max"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最高历史销量"
            v-model="spiderForm.goods_sell_num_max"
          />
        </a-form-model-item>
      </a-form-model-item>
      <a-form-model-item label="佣金金额区间">
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px', marginRight: '0' }"
          prop="goods_cos_fee_min"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最低佣金金额"
            v-model="spiderForm.goods_cos_fee_min"
          />
        </a-form-model-item>
        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px' }"
          prop="goods_cos_fee_max"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            placeholder="商品最高佣金金额"
            v-model="spiderForm.goods_cos_fee_max"
          />
        </a-form-model-item>
      </a-form-model-item>
      <a-form-model-item label="佣金率区间">
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px', marginRight: '0' }"
          prop="goods_cos_ratio_min"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            :max="100"
            placeholder="商品最低佣金率"
            v-model="spiderForm.goods_cos_ratio_min"
          />
        </a-form-model-item>
        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
        <a-form-model-item
          :style="{ display: 'inline-block', width: '120px' }"
          prop="goods_cos_ratio_max"
        >
          <a-input-number
            style="width: 100%"
            :min="0"
            :max="100"
            placeholder="商品最高佣金率"
            v-model="spiderForm.goods_cos_ratio_max"
          />
        </a-form-model-item>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary"  @click="handleSubmit">查询</a-button>
        <a-button @click="resetSubmit" style="margin-left: 20px">重置</a-button>
      </a-form-model-item>
    </a-form-model>
    <div style="margin: 10px 0">
      <a-button :loading="loading" :disabled="!selectedList.length" type="primary" @click="batchHandler">批量入库</a-button>
    </div>
    <div>
      <a-table
        :scroll="{ x: 1200, y: tableScrollY }"
        :rowKey="(record) => record.id"
        :columns="columns"
        :dataSource="tableData"
        :row-selection="rowSelection"
        :pagination="paginationStruct"
        :loading="loading"
        :locale="{ filterConfirm: '暂无数据' }"
        @change="pageChange"
      >
        <template slot="priceScope" slot-scope="column">￥{{ thousandsFormat(column) }}</template>
        <template slot="cosRatioScope" slot-scope="column">{{ column }}%</template>
        <template slot="cosFeeScope" slot-scope="column">￥{{ thousandsFormat(column) }}</template>
        <template slot="categoryScoped" slot-scope="column">{{ column }}</template>
        <template slot="coverScoped" slot-scope="row">
       <!--   <viewer>
            <img style="width: 50px;" :src="row.cover" :alt="row.title" v-if="row.cover !== ''" />
          </viewer>-->
          <my-image v-if="row.cover" :imgUrl="row.cover"></my-image>

        </template>
        <template slot="carouselScoped" slot-scope="row">
         <!-- <viewer>
            <img style="width: 50px;" :src="row.cover" :alt="row.title" v-if="row.cover !== ''" />
          </viewer>-->
          <my-image v-if="row.cover" :imgUrl="row.cover"></my-image>

        </template>
        <template slot="linkScoped" slot-scope="column">
          <a target="_blank" :href="column">{{ column }}</a>
        </template>
        <template slot="statusScoped" slot-scope="column">
          <span>{{ column === 0 ? '未入库' : '已入库' }}</span>
        </template>
        <template slot="operation" slot-scope="row">
          <a-button v-if="row.status === 0" type="primary" @click="warehousing(row.id)">入库</a-button>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import { getNewGoodsCategorys, getGoodsSpiderDetail, postGoodsPutIn } from '@/service/goods';
import { thousandsFormat } from '@/filters/shop';
const columns = [
  {
    title: '商品名称',
    dataIndex: 'title',
    fixed: 'left',
    width: 200,
  },
  {
    title: '商品ID',
    dataIndex: 'product_id',
    width: 200,
  },
  {
    title: '商品售价',
    dataIndex: 'price',
    scopedSlots: { customRender: 'priceScope' },
    width: 100,
  },
  {
    title: '佣金率',
    dataIndex: 'cos_ratio',
    scopedSlots: { customRender: 'cosRatioScope' },
    width: 100,
  },
  {
    title: '佣金',
    dataIndex: 'cos_fee',
    scopedSlots: { customRender: 'cosFeeScope' },
    width: 200,
  },
  {
    title: '商品类目',
    dataIndex: 'first_cid_name',
    scopedSlots: { customRender: 'categoryScoped' },
    width: 200,
  },
  {
    title: '历史总销量',
    dataIndex: 'sales',
    width: 200,
  },
  {
    title: '主图',
    scopedSlots: { customRender: 'coverScoped' },
    width: 80,
  },
  {
    title: '轮播图',
    scopedSlots: { customRender: 'carouselScoped' },
    width: 80,
  },
  {
    title: '所属店铺',
    dataIndex: 'shop_name',
    width: 200,
  },
  {
    title: '商品链接',
    dataIndex: 'detail_url',
    scopedSlots: { customRender: 'linkScoped' },
    width: 200,
  },
  {
    title: '入库状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'statusScoped' },
    width: 80,
  },
  {
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
    width: 80,
  },
]
export default defineComponent({
  name: 'featuredDetail',
  setup(_, ctx) {
    const handleSubmit = (e) => {
      e.preventDefault();
      ctx.refs.spiderRef.validate(async (validate) => {
        if (!validate) return
        paginationStruct.value.current = 1
        invokeGetGoodsSpiderFun()
      })
    }
    const resetSubmit = () => {
      paginationStruct.value.current = 1
      ctx.refs.spiderRef.resetFields()
      invokeGetGoodsSpiderFun()
    }
    const spiderForm = ref({
      goods_id: undefined,
      uid: ctx.root.$route.params.id,
      page: 1,
      page_size: 10,
      goods_title: undefined,
      goods_first_cid: undefined,
      goods_price_min: undefined,
      goods_price_max: undefined,
      goods_sell_num_min: undefined,
      goods_sell_num_max: undefined,
      goods_cos_fee_min: undefined,
      goods_cos_fee_max: undefined,
      goods_cos_ratio_min: undefined,
      goods_cos_ratio_max: undefined,
    })
    let tableData = ref([])
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY =ref(windowHeight - 375);
    const paginationStruct = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50'],
    })
    const selectedList = ref([])
    const loading = ref(false)
    const invokeGetGoodsCategory = async (params) => {
      const { res: { data } } = await getNewGoodsCategorys(params)
      categoryList.value = data
    }
    const pageChange = ({ current, pageSize }) => {
      paginationStruct.value.current = current
      paginationStruct.value.pageSize = pageSize
      invokeGetGoodsSpiderFun()
    }
    const invokeGetGoodsSpiderFun = async () => {
      const { current, pageSize } = paginationStruct.value
      loading.value = true
      const { goods_price_min, goods_price_max, goods_cos_fee_min, goods_cos_fee_max } = spiderForm.value
      const { res: { data } } = await getGoodsSpiderDetail({
        ...spiderForm.value,
        page: current + '',
        page_size: pageSize + '',
        goods_price_min: goods_price_min ? goods_price_min * 100 : undefined,
        goods_price_max: goods_price_max ? goods_price_max * 100 : undefined,
        goods_cos_fee_min: goods_cos_fee_min ? goods_cos_fee_min * 100 : undefined,
        goods_cos_fee_max: goods_cos_fee_max ? goods_cos_fee_max * 100 : undefined,
      })
      loading.value = false
      if (data.results) {
        tableData.value = data.results
        paginationStruct.value.total = data.count
      }
      selectedList.value = []
    }
    const categoryList = ref([])
    const filterCategoryOption = (input, option) => {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
    // const categoryName = (val) => {
    //   const items = categoryList.value.find(v => v.id === val)
    //   return items ? items.name : ''
    // }
    const warehousing = async (id) => {
      const { res } = await postGoodsPutIn({ id })
      if (res.success) {
        invokeGetGoodsSpiderFun()
        ctx.root.$message.success(`成功入库${res.data.success}个，失败${res.data.error}个`)
      }
    }
    const rowSelection = computed(() => (
      {
        onChange: async (selectedRowKeys) => {
          selectedList.value = selectedRowKeys
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.status === 1
          },
        }),
        selectedRowKeys: selectedList.value
      }
    ))
    const batchHandler = async () => {
      if (selectedList.value.length === 0) return
      warehousing(selectedList.value.toString())
    }
    onMounted(() => {
      invokeGetGoodsSpiderFun()
      invokeGetGoodsCategory({ level: 1 })
    })
    return {
      handleSubmit,
      spiderForm,
      tableScrollY,
      batchHandler,
      loading,
      columns,
      categoryList,
      resetSubmit,
      rowSelection,
      warehousing,
      thousandsFormat,
      invokeGetGoodsSpiderFun,
      pageChange,
      tableData,
      selectedList,
      filterCategoryOption,
      invokeGetGoodsCategory,
      paginationStruct
    }
  }
})
</script>

<style lang="less" scoped>
</style>
